// Imports => MOBX
import { observable, computed, action, makeObservable, runInAction } from 'mobx';

// Imports => Utilities
import { AcIsSet } from '@utils';

export class NavigatorStore {
	constructor(store) {
		makeObservable(this);

		this.addEvents();

		setTimeout(() => {
			window.requestAnimationFrame(() => {
				this.init();
			});
		}, 1000 / 60);
	}

	@observable
	connected = true;

	@observable
	options = {
		enableHighAccuracy: true,
		timeout: 60000,
		maximumAge: 0,
	};

	@observable
	currentLocation = {
		latitude: null,
		longitude: null,
	};

	@observable
	permitted = false;

	@computed
	get has_permission() {
		return this.permitted;
	}

	@computed
	get is_connected() {
		return this.connected;
	}

	@action
	verify_permission = () => {
		return this.permitted;
	};

	@action
	addEvents = () => {
		window.addEventListener('online', this.setConnectionStatus, false);
		window.addEventListener('offline', this.setConnectionStatus, false);
	};

	@action
	setConnectionStatus = () => {
		if (navigator && AcIsSet(navigator.onLine)) {
			this.connected = navigator.onLine;
		}
	};

	@action
	init = async () => {
		this.Navigator = navigator.geolocation;

		if (navigator && navigator.permissions) {
			await navigator.permissions
				.query({ name: 'geolocation' })
				.then((permissionStatus) => {
					if (
						permissionStatus &&
						['granted', 'prompt', 'fulfilled'].indexOf(permissionStatus.state) !== -1
					) {
						runInAction(() => (this.permitted = true));
					}
				});
		}

		if (this.Navigator) {
			this.Navigator.getCurrentPosition(this.onSuccess, this.onError, this.options);
			this.watch();
		}
	};

	@action
	permission_granted = () => {
		navigator.geolocation.getCurrentPosition(
			(obj) => {
				runInAction(() => (this.permitted = true));
			},
			(err) => {
				runInAction(() => (this.permitted = false));
			}
		);
	};

	@action
	watch = () => {
		this.Navigator.watchPosition(this.onSuccess, () => {}, this.options);
	};

	@action
	onSuccess = (object) => {
		this.permitted = true;

		const { coords } = object;
		const { latitude, longitude } = coords;

		this.currentLocation = {
			latitude,
			longitude,
		};
	};

	@action
	get = () => {
		return this.currentLocation;
	};

	@action
	onError = (err) => {
		if (err && err.code === 1) {
			this.permitted = false;
		}
	};
}

export default NavigatorStore;
