// Imports => MOBX
import { observable, action, makeObservable } from 'mobx';

const _default = {
	modal: {
		visible: false,
		title: '',
		body: [],
		actions: [],
		closeable: true,
		callback: () => {},
	},
	navigation: {
		visible: false,
	},
};

export class UiStore {
	constructor(store) {
		makeObservable(this);

		this.store = store;
	}

	@observable
	navigation = _default.navigation;

	@observable
	modal = _default.modal;

	@action
	set = (target, value) => {
		return new Promise((resolve) => {
			if (!target) return;
			if (!this[target]) return;
			if (typeof value === 'undefined' || value === null) return;

			this[target] = {
				...this[target],
				...value,
			};
			resolve();
		});
	};

	@action
	setValue = (target, property, value) => {
		return new Promise((resolve) => {
			if (!target) return;
			if (!this[target]) return;
			if (!property) return;
			if (typeof value === 'undefined' || value === null) return;

			this[target][property] = value;
			resolve();
		});
	};

	@action
	reset = (target) => {
		if (!target) return;
		if (!this[target]) return;

		this[target] = _default[target];
	};
}

export default UiStore;
